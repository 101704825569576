import {LitElement, html, css} from 'lit';
import {animate, stagger} from "motion";

const jobs = [
    {
        "name": "Reve",
        "title": "Member of Technical Staff", 
        "description": "Reimaging creative tooling for the AI age",
        "date": "August 2025 - ??",
        "logo": ""
    },
    {
        "name": "SketchPro.ai",
        "title": "Applied AI Research Intern", 
        "description": "Reimplemented entire diffusion backend for AI rendering, productionized papers sometimes the day they were released",
        "date": "Summer 2024",
        "logo": "../media/sp-logo.png"
    },
    {
        "name": "Niantic",
        "title": "SWE Intern, Campfire", 
        "description": "Launched social app to 4mil DAUs, did some agent stuff before it was cool",
        "date": "Summer 2023",
        "logo": ""
    },
    {
      "name": "AWS",
      "title": "SDE Intern, AWS Forecast", 
      "description": "Built some boring internal dashboard stuff for on call engineers",
      "date": "Summer 2022",
      "logo": ""
  }
]

export class TimelineElement extends LitElement {
  static get styles() {
    return css`
      :host {
        display: block;
        /* padding: 16px;
        max-width: 800px; */
        
        margin-bottom: 1rem;
     
      }

      .tl_main {
        display: flex;
        flex-direction: row;

        margin-bottom: 1rem;

        /* border: 1px solid rgba(255, 255, 255, 0.75);
        border-radius: 24px; */
      }

      .left {
        flex: 0.05;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        /* align-items: center; */
      }

      .right {
        flex: 0.95;
      }

      .name {
        color: #FFF;
        font-size: 0.6rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .title {
        color: rgba(255, 255, 255, 0.50);
        font-size: 0.5rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .description {
        color: rgba(255, 255, 255, 0.75);
        font-size: 0.45rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 0.2rem;
      }

      .date {
        color: rgba(255, 255, 255, 0.50);
        font-size: 0.45rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .logo {
        width: 0.75rem;
        height: 0.75rem;
        border: 1px solid white;
        border-radius: 0.25rem;

        img {
            width: 0.6rem;
            /* height: 0.75rem; */
            height: auto;
            /* transform: translateY(-0.3rem); */
        }
      }

      .top {
        display: flex;
        flex-direction: row;
        gap: 12px;
        justify-content: flex-start;
        align-items: center;

        margin-bottom: 0.2rem;
      }

      .circle {
        width: 0.5rem;
        height: 0.5rem;

        border-radius: 50%;

        border: 1px solid rgba(255, 255, 255, 0.5);
        background-color: transparent;

        /* Ensure the circle maintains its shape */
        /* From cursor :) */
        min-width: 0.5rem;
        min-height: 0.5rem;
        max-width: 0.5rem; 
        max-height: 0.5rem;
        box-sizing: border-box;
      }

      .vl {
        
        width: 1px;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        margin: 0;
        margin-top: 10px;
        margin-left: 0.25rem;
        padding: 0;
        border: none;
      }

      .blurred {
        /* z-index: 0; */
        position: relative;
        z-index: 10;
        color: rgba(255, 255, 255, 0.50);
        font-size: 0.45rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        height: 50%;

        text-align: center;
        cursor: pointer;

        transform: translateY(-75%);
        
        /* https://www.joshwcomeau.com/css/backdrop-filter/ */
        background: linear-gradient(
            to top,
            hsl(0deg 0% 0%) 0%,
            transparent 50%
        );
        backdrop-filter: blur(12px);
        /* mask-image: linear-gradient(
            to top,
            black 0% 50%,
            transparent 50% 100%
        ); */
        /* pointer-events: none; */
      }
    `;
  }

  static get properties() {
    return { 
        expanded: {type: Boolean}
    };
  }

  constructor() {
    super();
    this.expanded = false;
  }

  firstUpdated() {
    const elements = this.shadowRoot.querySelectorAll(".tl_main");
    animate(elements, { opacity: [0, 1], y: [50, 0] }, { delay: stagger(0.5, { start: 0.2 }), duration: 0.8 })
  }
  
  expand() {
    this.expanded = true;
  }

  render() {
    let jobsToRender;
    if(this.expanded) jobsToRender = jobs;
    else jobsToRender = jobs.slice(0, 3);
    return html`
        ${jobsToRender.map((job) => {
            return html`
                <div class="tl_main">
                    <div class="left">
                        <div class="circle"></div>
                        <hr class="vl"></hr>
                    </div>
                    <div class="right">
                        <div class="top">
                            <!-- <div class="logo"> -->
                                <!-- <img src=${job.logo} /> -->
                            <!-- </div> -->
                            <div class="name">
                                ${job.name}
                            </div>
                            <div class="title">
                                ${job.title}
                            </div>
                        </div>
                        <div class="description">
                            ${job.description}
                        </div>
                        <div class="date">
                            ${job.date}
                        </div>
                    </div>
                </div>
                
            `
        })}
        ${!this.expanded ? html`
            <div @click=${this.expand} class="blurred">
                tap to see more
            </div>
            
            ` : ``}
    `;
  }
}

window.customElements.define('timeline-element', TimelineElement);
